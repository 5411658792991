import axiosIns, {auth} from '@/libs/axios';
import router from '@/router';
import axios from 'axios';

class AuthService {
  login(user) {
    return axiosIns.post(
      'users/login/',
      {
        username: user.username,
        password: user.password,
      },
      {headers: auth()}
    );
  }

  async logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    await router.push('/');
  }

  register(user) {
    return axiosIns.post(
      'users/register/',
      {
        email: user.email,
        password: user.password,
        name: user.name,
      },
      {headers: auth()}
    );
  }

  userGeoData() {
    return axios.get('https://ipinfo.io/196.196.203.140?token=77826b765274e9', {headers: auth()});
  }
}

export default new AuthService();
