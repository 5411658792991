<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo align-items-center">
        <vuexy-logo />
        <h2 class="brand-text text-primary m-0 ml-1">Oscar Board</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col class="d-none d-lg-flex align-items-center p-5 h-100" lg="8">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5 h-100">
          <b-img :src="imgUrl" alt="Login V2" class="h-100 w-100" fluid style="object-fit: contain" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col class="d-flex align-items-center auth-bg px-2 p-lg-5" lg="4">
        <b-col class="px-xl-2 mx-auto" lg="12" md="6" sm="8">
          <b-card-title class="font-weight-bold mb-1" title-tag="h2"> Welcome to {{ appName }}! 👋</b-card-title>
          <b-card-text class="mb-2">Please log in to your account and start the adventure</b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Username" label-for="login-email">
                <validation-provider #default="{errors}" name="Username" rules="required">
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="oscar.b"
                    @keydown.enter="login"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label text="">Password</label>
                  <!--                                    <b-link :to="{name:'reset-password'}">-->
                  <!--                                        <small>Забыли пароль?</small>-->
                  <!--                                    </b-link>-->
                </div>
                <validation-provider #default="{errors}" name="Password" rules="required">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null" class="input-group-merge">
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="············"
                      @keydown.enter="login"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!--                            <b-form-group>-->
              <!--                                <b-form-checkbox-->
              <!--                                    id="remember-me"-->
              <!--                                    v-model="status"-->
              <!--                                    name="checkbox-1"-->
              <!--                                >-->
              <!--                                    Запомнить меня-->
              <!--                                </b-form-checkbox>-->
              <!--                            </b-form-group>-->

              <!-- submit buttons -->
              <b-button v-if="!loadingStatus" block class="rounded-nrm" variant="primary" @click="login">
                <template>
                  {{ $t('Login') }}
                </template>
              </b-button>
              <b-button v-if="loadingStatus" block class="rounded-lg" disabled variant="primary">
                <b-spinner small variant="white" />
              </b-button>
            </b-form>
          </validation-observer>

          <!--          <b-card-text class="text-center mt-2">-->
          <!--            <span>First time on the platform?</span>-->
          <!--            <b-link :to="{name: 'register'}">-->
          <!--              <span>Create an account</span>-->
          <!--            </b-link>-->
          <!--          </b-card-text>-->

          <!-- divider -->
          <!--                    <div class="divider my-2">-->
          <!--                        <div class="divider-text">-->
          <!--                            или-->
          <!--                        </div>-->
          <!--                    </div>-->

          <!-- social buttons -->
          <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
          <!--                                            <b-button-->
          <!--                                                variant="facebook"-->
          <!--                                                href="javascript:void(0)"-->
          <!--                                            >-->
          <!--                                                <feather-icon icon="FacebookIcon"/>-->
          <!--                                            </b-button>-->
          <!--                                            <b-button-->
          <!--                                                variant="twitter"-->
          <!--                                                href="javascript:void(0)"-->
          <!--                                            >-->
          <!--                                                <feather-icon icon="TwitterIcon"/>-->
          <!--                                            </b-button>-->
          <!--            <b-button :href="getGoogleUrl('/')" variant="google">-->
          <!--              <feather-icon icon="MailIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button :href="getGitHubUrl('/')" variant="github">-->
          <!--              <feather-icon icon="GithubIcon" />-->
          <!--            </b-button>-->
          <!--          </div>-->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import {email, required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {$themeConfig} from '@themeConfig'
import AuthService from '@/services/auth.service'

import {getGitHubUrl, getGoogleUrl} from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // eslint-disable-next-line no-undef
      sideImg: require('@/assets/images/pages/visual-data-animate.svg'),
      required,
      email,
      loadingStatus: false,
      appName: $themeConfig.app.appName,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = $themeConfig.app.appLogoImage
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    getGitHubUrl,
    getGoogleUrl,
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Форма отправлена',
                icon: 'EditIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            }
          )
        }
      })
    },
    login() {
      store.commit('osBoard/SET_TIMESTAMP', 0)
      this.loadingStatus = true
      AuthService.login({
        username: this.userEmail,
        password: this.password,
      })
        .then(response => {
          localStorage.setItem('access_token', response.data.access)
          document.cookie = 'access_token=' + response.data.access
          if (localStorage.getItem('redirect_url') !== null) {
            // eslint-disable-next-line no-undef
            this.$router.push(localStorage.getItem('redirect_url'))
            localStorage.removeItem('redirect_url')
          } else {
            this.$router.push({name: 'home'})
          }
          this.loadingStatus = false
        })
        .catch(() => {
          this.loadingStatus = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Внимание!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Данные пользователя не найдены (401).',
              },
            },
            {
              position: 'top-center',
            }
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.brand-logo {
  svg {
    height: 40px;
  }
}
</style>